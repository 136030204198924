<template>
  <div>
    <v-card>
      <v-card-title class="headline justify-center">
        Key Metrics
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- Physicality -->
        <v-row dense>
          <v-col cols="12">
            <h3>Physicality</h3>
          </v-col>
          <!-- Height -->
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-select
              label="Height"
              hint="How tall are you?"
              :persistent-hint="editing"
              :items="heights"
              v-model="model.height"
              placeholder="Unanswered"
              :disabled="!editing"
              color="color3"
              item-color="color3"
              attach
            ></v-select>
          </v-col>
          <!-- Hand -->
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-select
              label="Dominant Hand"
              hint="What hand do you swing with?"
              :persistent-hint="editing"
              :items="['Left', 'Right', 'Ambidextrous']"
              v-model="model.metrics.dominantHand"
              placeholder="Unanswered"
              :disabled="!editing"
              color="color3"
              item-color="color3"
              attach
            ></v-select>
          </v-col>
          <!-- Reach -->
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-select
              label="Standing Reach"
              hint="What is the height point you can touch standing flat footed?"
              :persistent-hint="editing"
              :items="vertOptions"
              v-model="model.metrics.reach"
              placeholder="Unanswered"
              :disabled="!editing"
              color="color3"
              item-color="color3"
              attach
            ></v-select>
          </v-col>
          <!-- Wingspan -->
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-select
              label="Wingspan"
              hint="What is the measurement between your left figertips to your right, with your arms stretched out?"
              :persistent-hint="editing"
              :items="heights"
              v-model="model.metrics.wingspan"
              placeholder="Unanswered"
              :disabled="!editing"
              color="color3"
              item-color="color3"
              attach
            ></v-select>
          </v-col>
        </v-row>
        <!-- Athleticism -->
        <v-row dense>
          <v-col cols="12">
            <h3>Athleticism</h3>
          </v-col>
          <!-- Block Jump -->
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-select
              label="Block Jump Touch"
              hint="What is the highest point you can touch, jumping off both feet with NO steps?"
              :persistent-hint="editing"
              :items="vertOptions"
              v-model="model.metrics.blockTouch"
              placeholder="Unanswered"
              :disabled="!editing"
              color="color3"
              item-color="color3"
              attach
            ></v-select>
          </v-col>
          <!-- Approach Jump -->
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-select
              label="Approach Jump Touch"
              hint="What is the highest point you can touch with a full approach?"
              :persistent-hint="editing"
              :items="vertOptions"
              v-model="model.metrics.approachTouch"
              placeholder="Unanswered"
              :disabled="!editing"
              color="color3"
              item-color="color3"
              attach
            ></v-select>
          </v-col>
          <!-- Star Drill -->
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field
              label="Star Drill"
              hint="What is your fastest star drill time (in seconds)?"
              :persistent-hint="editing"
              v-model="model.metrics.starDrill"
              placeholder="Unanswered"
              :disabled="!editing"
              color="color3"
              item-color="color3"
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense v-if="view.td">
          <verify-metrics-dialog :player="player"></verify-metrics-dialog>
        </v-row>
        <!-- Beach vs Indoor -->
        <v-row dense>
          <v-col cols="12">
            <h3>Beach vs Indoor</h3>
          </v-col>
          <v-col cols="6" sm="4" lg="3"><!-- Beach -->
            <v-radio-group v-model="model.metrics.beach" hide-details>
              <template v-slot:label>
                <div style="width: 100%">Do you play beach?</div>
              </template>
              <v-radio
                color="color3"
                :disabled="!editing"
                label="Yes"
                :value="true"
              ></v-radio>
              <v-radio
                color="color3"
                :disabled="!editing"
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" sm="4" lg="3"><!-- Indoor -->
            <v-radio-group v-model="model.metrics.indoor" hide-details>
              <template slot="label" class="mr-4">
                Do you play indoor?
              </template>
              <v-radio
                color="color3"
                :disabled="!editing"
                label="Yes"
                :value="true"
              ></v-radio>
              <v-radio
                color="color3"
                :disabled="!editing"
                label="No"
                :value="false"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-scroll-x-transition>
            <v-col cols="12" sm="4" lg="6" v-show="model.metrics.indoor && model.metrics.beach">
              <v-select
                color="color3"
                item-color="color3"
                :disabled="!editing"
                :persistent-hint="editing"
                :items="['I am a beach player', 'I am a beach player that plays indoor', 'I am a beach and indoor player', 'I am an indoor player that plays beach', 'I am an indoor player']"
                v-model="model.jProps.beachVindoor"
                label="Beach vs Indoor"
                hint="Which best describes you as a player?"
              ></v-select>
            </v-col>
          </v-scroll-x-transition>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Beach -->
    <v-expand-transition>
      <v-card v-if="model.metrics.beach" class="mt-4">
        <v-card-title class="headline justify-center">
          Beach
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row dense><!-- Position -->
            <v-col cols="12">
              <h3>Position</h3>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3"><!-- Side -->
              <v-select
                label="Side"
                hint="What side are you currently playing?"
                :persistent-hint="editing"
                :items="['Left', 'Right', 'Both']"
                v-model="model.metrics.side"
                placeholder="Unanswered"
                :disabled="!editing"
                color="color3"
                item-color="color3"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3"><!-- Side Pref -->
              <v-select
                label="Preferred Side"
                hint="What side do you prefer to play?"
                :persistent-hint="editing"
                :items="['Left', 'Right', 'No Preference']"
                v-model="model.metrics.sidePreferred"
                placeholder="Unanswered"
                :disabled="!editing"
                color="color3"
                item-color="color3"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3"><!-- Block/D -->
              <v-select
                label="Blocker or Defender"
                hint="Do you usually block, play defense, or split?"
                :persistent-hint="editing"
                :items="['Block', 'Defense', 'Split']"
                v-model="model.metrics.defense"
                placeholder="Unanswered"
                :disabled="!editing"
                color="color3"
                item-color="color3"
                attach
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3"><!-- Block/D Pref -->
              <v-select
                label="Defensive Preference"
                hint="Do you prefer blocking, playing defense, or spliting?"
                :persistent-hint="editing"
                :items="['Blocking', 'Defense', 'Spliting', 'No Preference']"
                v-model="model.metrics.defensePreferred"
                placeholder="Unanswered"
                :disabled="!editing"
                color="color3"
                item-color="color3"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="player.recruitFields"><!-- Programs -->
            <v-col cols="12">
              <h3>Programs</h3>
            </v-col>
            <v-col cols="12"><!-- Beach Club -->
              <program-form
                program="Club"
                question="Do you play beach for a club?"
                :contacts="['Club Director', 'Club Coach']"
                :model="model.jProps.programs.beach.club"
                :disable="!editing"
              ></program-form>
            </v-col>
            <v-col cols="12">
              <program-form
                program="High School"
                question="Do you play beach for your school?"
                :contacts="['Coach']"
                :model="model.jProps.programs.beach.hs"
                :disable="!editing"
                @yes-or-no-change="setHsName"
              ></program-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>
    <!-- Indoor -->
    <v-expand-transition>
      <v-card v-if="model.metrics.indoor" class="mt-4">
        <v-card-title class="headline justify-center">
          Indoor
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row dense><!-- Position -->
            <v-col cols="12">
              <h3>Position</h3>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-select
                :items="positions"
                v-model="model.metrics.primaryPosition"
                :label="`Primary Position`"
                color="color3"
                item-color="color3"
                hint="What position do you play most?"
                :persistent-hint="editing"
                placeholder="Unanswered"
                :disabled="!editing"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-select
                :items="positions"
                v-model="model.metrics.preferredPosition"
                label="Preferred Position"
                color="color3"
                item-color="color3"
                hint="If you could choose your position, what would it be?"
                :persistent-hint="editing"
                placeholder="Unanswered"
                :disabled="!editing"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :items="positions"
                v-model="model.metrics.positionsPlayed"
                label="Positions Played"
                color="color3"
                item-color="color3"
                hint="Select all the positions you have experience playing?"
                :persistent-hint="editing"
                placeholder="Unanswered"
                :disabled="!editing"
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense v-if="player.recruitFields"><!-- Programs -->
            <v-col cols="12">
              <h3>Programs</h3>
            </v-col>
            <v-col cols="12"><!-- Beach Club -->
              <program-form
                program="Club"
                question="Do you play indoor for a club?"
                :contacts="['Club Director', 'Club Coach']"
                :model="model.jProps.programs.indoor.club"
                :disable="!editing"
              ></program-form>
            </v-col>
            <v-col cols="12">
              <program-form
                program="High School"
                question="Do you play indoor for your school?"
                :contacts="['Coach']"
                :model="model.jProps.programs.indoor.hs"
                :disable="!editing"
                @yes-or-no-change="setHsNameIndoor"
              ></program-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-fab-transition><!-- CANCEL -->
      <v-btn
        color="success white--text"
        fab
        :small="smallFab"
        fixed bottom right
        @click.stop="onSaveClick"
        :loading="loading"
        :disabled="!saveable"
        v-if="editing"
        :style="smallFab ? 'margin-right: 45px;' : 'margin-right: 65px;'"
      >
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition><!-- EDIT/SAVE -->
      <v-btn
        v-if="fab"
        :color="fab.color"
        fab
        :small="smallFab"
        :key="fab.key"
        @click.stop="fab.click"
        :loading="fab.loading"
        :disabled="fab.disabled"
        fixed bottom right
      >
        <v-icon>{{fab.icon}}</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import VertHeights from '@/classes/VertHeights'
import Heights from '@/classes/Heights'
import ProgramForm from '@/components/Forms/ProgramForm'
const VerifyMetricsDialog = () => import('@/components/Player/Editor/VerifyMetricsDialog.vue')

export default {
  props: ['player', 'view', 'active'],
  data () {
    return {
      editing: false,
      editable: null,
      height: null,
      loading: false,
      positions: [
        { text: 'Libero', value: 'Libero' },
        { text: 'Middle', value: 'Middle' },
        { text: 'Outside', value: 'Outside' },
        { text: 'Opposite/Rightside', value: 'Opposite/Rightside' },
        { text: 'Setter', value: 'Setter' }
      ],
      beachClub: false,
      beachHS: false
    }
  },
  computed: {
    model () {
      return this.editing ? this.editable : this.dataOg
    },
    smallFab () {
      return this.$vuetify.breakpoint.smAndDown
    },
    fab () {
      return this.editing ? {
        key: 'cancel',
        color: 'error white--text',
        icon: 'fas fa-times',
        click: this.cancel,
        loading: this.loading
      } : {
        key: 'edit',
        color: 'color3 color3Text--text',
        icon: 'fas fa-pencil',
        click: this.edit,
        loading: false,
        disabled: this.loading
      }
    },
    dataOg () {
      return {
        id: this.player.id,
        height: this.player.height,
        metrics: this.player.metrics,
        jProps: this.player.jProps
      }
    },
    dirty () {
      return this.dirtyObj.height || this.dirtyObj.metrics || this.dirtyObj.jProps
    },
    dirtyObj () {
      return {
        height: this.editable && this.editable.height !== this.dataOg.height,
        metrics: this.editable && JSON.stringify(this.editable.metrics) !== JSON.stringify(this.dataOg.metrics),
        jProps: this.editable && JSON.stringify(this.editable.jProps) !== JSON.stringify(this.dataOg.jProps)
      }
    },
    saveable () {
      return this.dirty
    },
    dto () {
      if (!this.editable) return null
      const a = {
        id: this.player.id
      }
      if (this.dirtyObj.height) a.height = this.editable.height
      if (this.dirtyObj.metrics) {
        a.metrics = {}
        for (const prop in this.dataOg.metrics) {
          if (JSON.stringify(this.editable.metrics[prop]) !== JSON.stringify(this.dataOg.metrics[prop])) {
            a.metrics[prop] = this.editable.metrics[prop]
          }
        }
      }
      if (this.dirtyObj.jProps) {
        a.jsonProps = JSON.stringify(this.editable.jProps)
      }

      return a
    },
    vertOptions () {
      return VertHeights
    },
    heights () {
      return Heights
    }
  },
  methods: {
    edit () {
      this.editable = JSON.parse(JSON.stringify(this.dataOg))
      this.editing = true
    },
    cancel () {
      this.editing = false
      this.editable = null
    },
    onSaveClick () {
      console.log(this.dto)
      this.loading = true
      this.$VBL.player.patch(this.dto)
        .then(r => {
          this.onSaved(this.dto)
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    onSaved (result) {
      result && this.player.update(result)
      this.editing = false
    },
    setHsName (v) {
      if (v && this.player.highSchool) {
        this.model.jProps.programs.beach.hs.name = this.player.highSchool
      }
    },
    setHsNameIndoor (v) {
      if (v && this.player.highSchool) {
        this.model.jProps.programs.indoor.hs.name = this.player.highSchool
      }
    }
  },
  watch: {
    active: function (val) {
      if (!val) {
        this.cancel()
      }
    }
  },
  components: {
    ProgramForm,
    VerifyMetricsDialog
  }
}
</script>

<style scoped>
>>> .v-input--radio-group__input {
  align-items: flex-start;
}
</style>
