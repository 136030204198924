const options = []

for (let f = 3; f <= 12; f++) {
  options.push(`${f}'`)
  for (let i = 1; i <= 11; i++) {
    options.push(`${f}' ${i}"`)
  }
}

export default options
